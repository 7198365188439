<template>
  <div>
    <ManageAcademicConferences4 />
  </div>
</template>
<script>
import ManageAcademicConferences4 from "@/components/ManageAcademicJournal/ManageAcademicConferences4";
export default {
  components: {
    ManageAcademicConferences4,
  },
  created() {},
};
</script>
