<template>
  <v-row>
    <v-card class="pa-3" flat color="#f5f5f5">
      <v-row>
        <v-col cols="12" md="10" sm="10" class="">
          <span
            style="
              font-family: 'IBM Plex Sans Thai';
              font-style: normal;
              font-weight: 600;
              font-size: 24px;
              line-height: 40px;
            "
          >
            ส่งบทความวิชาการ การสื่อสารและการจัดการ
          </span>
        </v-col>
        <v-col cols="12" md="2" sm="2" class="text-right">
          <v-btn
            @click="submit('q_1_1')"
            color="#2AB3A3"
            dark
            elevation="0"
            style="
              border-radius: 0px;
              padding: 12px 16px;
              gap: 8px;
              width: 114px;
              height: 48px;
            "
          >
            บันทึก
          </v-btn>
        </v-col>
      </v-row>

      <v-form ref="createForm" v-model="checkValidate" :lazy-validation="lazy">
        <v-card class="pa-6 mt-4" width="100%" flat>
          <v-tabs v-model="e1">
            <v-col
              cols="4"
              md="3"
              sm="3"
              :style="
                e1 == 0
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive">
                <span><b>ภาษาไทย</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="4"
              md="3"
              sm="3"
              :style="
                e1 == 1
                  ? 'border-bottom: 3px solid #2ab3a3'
                  : 'border-bottom: 1px solid #2ab3a3'
              "
            >
              <v-tab class="textResponsive">
                <span><b>English</b></span>
              </v-tab>
            </v-col>
            <v-col
              cols="4"
              md="6"
              sm="6"
              style="border-bottom: 1px solid #2ab3a3"
            >
              <v-tab class="text-h6"> </v-tab>
            </v-col>

            <v-tabs-slider color="#2AB3A3"> </v-tabs-slider>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12"
                      ><span class="pt-2">หัวข้อ (TH)</span>
                      <span style="color: red">*</span>
                      <v-text-field
                        hide-details
                        v-model="form.name_th"
                        placeholder="ระบุหัวข้อ (TH)"
                        dense
                        outlined
                        :rules="rules.required"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12"
                      ><span class="pt-2">เนื้อหา (TH)</span>
                      <span style="color: red">*</span>
                      <quill-editor
                        class="quill900"
                        v-model="form.submission_details_th"
                        :content="content"
                        :options="editorOption"
                        @change="onEditorChange($event)"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12" sm="12"
                      ><span class="pt-2">หัวข้อ (EN)</span>
                      <v-text-field
                        hide-details
                        v-model="form.name_en"
                        placeholder="ระบุหัวข้อ (EN)"
                        dense
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" sm="12"
                      ><span class="pt-2">เนื้อหา (EN)</span>
                      <quill-editor
                        class="quill900"
                        v-model="form.submission_details_en"
                        :content="content1"
                        :options="editorOption"
                        @change="onEditorChange1($event)"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
            <span
              style="
                font-family: 'IBM Plex Sans Thai';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 40px;
              "
            >
              อัพโหลดเอกสารประกอบ
            </span>
          </v-col>
        </v-row>

        <v-card class="pa-6" width="100%" flat>
          <v-row>
            <v-col cols="12" md="9" sm="9">
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                  sm="12"
                  v-for="(item, indexItemDocs) in this.documentFiles"
                  :key="indexItemDocs"
                >
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <p
                        style="color: #4caf50; cursor: pointer"
                        @click="onClickFile(item.path)"
                      >
                        {{ item.name }}
                      </p>
                    </v-col>
                    <v-col cols="12" md="9" sm="9" class="mt-n3">
                      <v-btn
                        color="red"
                        icon
                        @click="deleteFiles(indexItemDocs)"
                      >
                        <v-icon>mdi-alpha-x-circle-outline</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                  <input
                    type="file"
                    accept="*,"
                    id="file"
                    ref="form.attachFile1"
                    v-on:change="submitFile('form.attachFile1')"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <v-row>
          <v-col cols="12" md="12" sm="12" class="pa-6 mt-10">
            <span
              style="
                font-family: 'IBM Plex Sans Thai';
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 40px;
              "
            >
              ลิ้งสำหรับส่งบทความ
            </span>
          </v-col>
        </v-row>
        <v-card class="pa-6" width="100%" flat>
          <v-row>
            <v-col cols="12" md="12" sm="12"
              ><span class="pt-2">ลิ้งสำหรับส่งบทความ</span>
              <span style="color: red">*</span></v-col
            >
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                hide-details
                v-model="form.article_link"
                placeholder="ระบุลิ้งสำหรับส่งบทความ"
                dense
                outlined
                :rules="rules.required"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
      </v-form>

      <v-row class="mt-7">
        <v-col cols="12" md="12" sm="12" class="text-right">
          <v-btn
            @click="submit('q_1_1')"
            color="#2AB3A3"
            dark
            elevation="0"
            style="
              border-radius: 0px;
              padding: 12px 16px;
              gap: 8px;
              width: 114px;
              height: 48px;
            "
          >
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>
<script>
import { Decode } from "@/services";
export default {
  data() {
    return {
      e1: 0,
      items: [],
      documentFiles: [],

      getToday: new Date().toLocaleString(),
      today: "",
      status: "เปิดใช้งาน",
      statusItems: [
        { id: 1, name: "เปิดใช้งาน" },
        {
          id: 2,
          name: "ปิดใช้งาน",
        },
        {
          id: 3,
          name: "ฉบับร่าง",
        },
      ],
      form: {
        name_en: "",
        name_th: "",
        submission_details_en: "",
        submission_details_th: "",
        type_th: "ส่งบทความวิชาการการสื่อสารและการจัดการ",
        article_link: "",
      },

      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      checkValidate1: true,
      lazy: false,
      content: "",
      content1: "",
      editorOption: {
        placeholder: "ระบุข้อมูล",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  computed: {
    isWindowWidthGreaterThan768() {
      return window.innerWidth > 768;
    },
  },
  created() {
    this.getAll();
    document.title = "วารสารวิชาการ - GSCM Management System";
  },

  methods: {
    async getAll() {
      this.loading = true;
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/academicConferences/16`
      );
      console.log("getAll", response.data.data);
      this.form = response.data.data;
      // this.imgUrl = response.data.data.imgUrl;
      for (let i in this.items) {
        this.items[i].sortindex = parseInt(i) + 1;
      }
      this.loading = false;
    },
    deleteFiles(val) {
      this.documentFiles.splice(val, 1);
    },
    onClickFile(val) {
      window.open(val, "_blank");
    },

    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    onEditorChange1({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content1 = html;
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    cancel() {
      this.$router.push("ManageNews");
    },
    async submitFile(questionNo) {
      console.log("this.$refs[questionNo]", this.$refs[questionNo]);
      if (this.$refs[questionNo]) {
        this.loading = true;
        if (this.$refs[questionNo].files[0]) {
          let formData = new FormData();
          formData.append("files", this.$refs[questionNo].files[0]);
          formData.append("filename", this.$refs[questionNo].files[0].name);
          formData.append("type", questionNo);
          // formData.append("userId", this.userId);
          console.log("formData", formData);
          const auth = {
            headers: {
              "Content-Type": "multipart/form-data",
              // Authorization: `Bearer` + " " + this.checkuser.token,
            },
          };
          console.log("auth", auth);
          this.uploaded = true;
          const response = await this.axios.post(
            `https://gscm-service.yuzudigital.com/files/formData`,
            formData,
            auth
          );
          if (questionNo == "form.attachFile1") {
            // this.form.attachFile1 = response.data.data.path;
            this.documentFiles.push({
              name: this.$refs[questionNo].files[0].name,
              path: response.data.data.path,
            });
          }
          console.log("this.documentFiles", this.documentFiles);

          console.log("SUCCESS!!");
          console.log("response", response);
        }
        this.loading = false;
      }
    },

    async submit(pic) {
      console.log(this.file, "f");
      if (this.$refs.createForm.validate(true)) {
        // if (this.type == "CONTENT") {
        // await this.submitfileMain(pic);
        console.log("pic", pic);
        // }
        this.user = JSON.parse(
          Decode.decode(localStorage.getItem("gscmAdmin"))
        );
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        const data = {
          name_th: this.form.name_th,
          name_en: this.form.name_en,
          submission_details_th: this.form.submission_details_th,
          submission_details_en: this.form.submission_details_en,
          type_th: this.form.type_th,
          article_link: this.form.article_link,
          documentFiles: this.documentFiles,
        };
        console.log("data111", data);
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/academicConferences/16`,
          data,
          auth
        );
        console.log("createBanner", response);
        console.log(response.data.response_status);
        if (response.data.response_status == "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            text: `บันทึกสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          // this.$router.push("ManageNews");
        } else {
          this.$swal.fire({
            icon: "error",
            text: response.data.response_description,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>

 